import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Heatmap = lazy(() => import('./pages/heatmap'));
const Activities = lazy(() => import('./pages/activities'));
const Fleets = lazy(() => import('./pages/fleets'));
const VehicleComparison = lazy(() => import('./pages/vehicleComparison'));
const DriverAwards = lazy(() => import('./pages/driverAwards'));
const Performance = lazy(() => import('./pages/performance'));

export const ReportsRoutes: RouteObject[] = [
  {
    path: links.nexpro.reports.heatmap,
    element: <Heatmap />,
  },
  {
    path: links.nexpro.reports.activities,
    element: <Activities />,
  },
  {
    path: links.nexpro.reports.fleets,
    element: <Fleets />,
  },
  {
    path: links.nexpro.reports.vehicleComparison,
    element: <VehicleComparison />,
  },
  {
    path: links.nexpro.reports.driverAwards,
    element: <DriverAwards />,
  },
  {
    path: links.nexpro.reports.performance,
    element: <Performance />,
  },
];
