import { Search } from 'lucide-react';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { Badge } from '@/common/components/Badge';
import {
  Breadcrumb,
  IBreadcrumb,
} from '@/common/components/Header/components/Breadcrumb';

import * as style from './styles';

interface IFiltersHeaderProps {
  title: string;
  breadcrumbs?: IBreadcrumb[];
  children?: ReactNode;
  filters?: string[];
  hasDate?: boolean;
  onRemoveFilter?: (filter: string) => void;
  onClearFreeSearch?: () => void;
  onClearFilters?: () => void;
  dashboardBadge?: string;
}

export function FiltersHeader({
  title,
  breadcrumbs,
  children,
  filters,
  hasDate,
  onRemoveFilter,
  onClearFreeSearch,
  onClearFilters,
  dashboardBadge,
}: IFiltersHeaderProps) {
  const hookForm = useFormContext();

  const freeSearch: string = hookForm.watch('freeSearch') || '';

  const handleClearFreeSearch = () => {
    hookForm.setValue('freeSearch', '');
    if (onClearFreeSearch) onClearFreeSearch();
  };

  const handleClearFilters = () => {
    hookForm.reset();
    if (onClearFilters) onClearFilters();
  };

  return (
    <header className={style.container()}>
      <div className={style.heading()}>
        <div className={style.titleContainer()}>
          <h1 className={style.title()}>{title}</h1>
          {dashboardBadge && (
            <div className={style.dashboardBadge()}>{dashboardBadge}</div>
          )}
        </div>

        <div className={style.actionsWrapper({ hasDate })}>{children}</div>
      </div>

      <div className={style.headerFooter()}>
        <div className={style.breadCrumbs()}>
          {breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb
              key={breadcrumb?.title}
              isLink={
                Boolean(breadcrumb?.link) && index < breadcrumbs?.length - 1
              }
              isActive={index !== 0 && index === breadcrumbs?.length - 1}
              isLast={index === breadcrumbs?.length - 1}
              data={breadcrumb}
            />
          ))}
        </div>

        <div className={style.badgesContainer()}>
          {freeSearch && (
            <Badge
              title={freeSearch}
              LeadingIcon={<Search size={12} />}
              onRemove={handleClearFreeSearch}
            />
          )}

          {filters?.map(filter => (
            <Badge
              key={filter}
              title={filter}
              onRemove={() => onRemoveFilter && onRemoveFilter(filter)}
            />
          ))}

          {freeSearch || (filters && filters?.length > 0) ? (
            <Badge
              title="Limpar filtros"
              onRemove={handleClearFilters}
              type="danger"
            />
          ) : null}
        </div>
      </div>
    </header>
  );
}
