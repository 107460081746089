import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Vehicles = lazy(() => import('./pages/vehicles'));
const Driving = lazy(() => import('./pages/driving'));
const Drivers = lazy(() => import('./pages/drivers'));

export const PerformanceRoutes: RouteObject[] = [
  {
    path: links.nexpro.performance.vehicles,
    element: <Vehicles />,
  },
  {
    path: links.nexpro.performance.driving,
    element: <Driving />,
  },
  {
    path: links.nexpro.performance.drivers,
    element: <Drivers />,
  },
];
