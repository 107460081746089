import Cookies from 'js-cookie';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import SidebarSkeleton from '@/common/components/SidebarSkeleton';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { useToast } from '@/common/hooks/useToast';
import { getItemLocal, removeItemLocal } from '@/common/utils/localStorage';
import { geofenceHttpClient } from '@/config/geofenceHttpClient';
import { httpClient } from '@/config/httpClient';
import { httpSalesWeb } from '@/config/httpSalesWeb';
import { metricsHttpClient } from '@/config/metricsHttpClient';
import { telemetryHttpClient } from '@/config/telemetryHttpClient';

export function AppRoutes() {
  const { app, matchApp, changeApp } = useAppStore();
  const { t } = useTranslation('login/signIn');
  const { toast } = useToast();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const sessionState = Cookies.get('sessionState');

    if (!accessToken || !refreshToken || !sessionState) {
      const pastLoggedApp = getItemLocal('app', { parse: false });

      if (
        pastLoggedApp &&
        pastLoggedApp !== EnumAPPS.LOGIN &&
        !httpClient.defaults.headers.Authorization
      ) {
        toast.info(t('sessionExpired'), t('signInToContinue'), {
          toastId: 'session-expired',
          autoClose: 6000,
        });

        removeItemLocal('app');
        changeApp(EnumAPPS.LOGIN);
      }

      return;
    }

    httpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
    metricsHttpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
    geofenceHttpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
    telemetryHttpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
    httpSalesWeb.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }, [toast, t]);

  return (
    <Suspense fallback={<SidebarSkeleton />}>
      <RouterProvider router={matchApp(app)} />
    </Suspense>
  );
}
