import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { useUserStore } from '@/common/hooks/UserStore';
import { UserRoleEnum } from '@/common/types/Role';

const Dashboard = lazy(() => import('./pages/dashboard'));
const DriverDashboard = lazy(() => import('./pages/driverDashboard'));

function DashboardElement() {
  const { role } = useUserStore();

  if (role === UserRoleEnum.DRIVER) {
    return <DriverDashboard />;
  }
  return <Dashboard />;
}

export const DashboardRoutes: RouteObject[] = [
  {
    index: true,
    element: <Dashboard />,
  },
];
