import {
  CheckCircle,
  FileText,
  Home,
  LineChart,
  MapPin,
  Orbit,
  PlusSquare,
  SlidersHorizontal,
  Users,
} from 'lucide-react';

import { IAppNavigation } from '@/common/types/AppNavigation';
import { links } from '@/common/utils/links';

export const NexproAppNavigation = (): IAppNavigation[] => {
  return [
    {
      key: 'dashboard',
      title: 'sidebar/nexproMenus:dashboard',
      icon: Home,
      link: '/',
    },
    {
      key: 'clientes',
      title: 'sidebar/backofficeMenus:clients',
      icon: Users,
      link: links.clients.list,
      flag: 'sidebar_clients_menu',
    },
    {
      key: 'performance',
      title: 'sidebar/nexproMenus:performance',
      icon: LineChart,
      link: '#',
      flag: 'sidebar_performance',
      children: [
        {
          key: 'performanceVehicles',
          title: 'sidebar/nexproMenus:vehicles',
          link: links.nexpro.performance.vehicles,
        },
        // {
        //   key: 'performanceDrivers',
        //   title: 'sidebar/nexproMenus:drivers',
        //   link: links.nexpro.performance.drivers,
        // },
        {
          key: 'performanceDriving',
          title: 'sidebar/nexproMenus:driving',
          link: links.nexpro.performance.driving,
        },
      ],
    },
    {
      key: 'monitoring',
      title: 'sidebar/nexproMenus:monitoring',
      icon: MapPin,
      link: links.nexpro.monitoring,
      flag: 'sidebar_monitoring',
    },
    // {
    //   key: 'registrations',
    //   title: 'sidebar/nexproMenus:registrations',
    //   icon: FileStack,
    //   link: '#',
    //   flag: 'sidebar_registrations',
    //   children: [
    //     {
    //       key: 'registrationsDrivers',
    //       title: 'sidebar/nexproMenus:drivers',
    //       link: links.nexpro.registrations.drivers,
    //     },
    //     {
    //       key: 'registrationsVehicles',
    //       title: 'sidebar/nexproMenus:vehicles',
    //       link: links.nexpro.registrations.vehicles,
    //     },
    //     {
    //       key: 'registrationsGroups',
    //       title: 'sidebar/nexproMenus:groups',
    //       link: links.nexpro.registrations.groups,
    //     },
    //     // {
    //     //   key: 'registrationsMaintenance',
    //     //   title: 'sidebar/nexproMenus:maintenance',
    //     //   link: links.nexpro.registrations.maintenance,
    //     //   flag: 'sidebar_maintenance_menu',
    //     // },
    //   ],
    // },
    {
      key: 'geofences',
      title: 'sidebar/nexproMenus:geofences',
      icon: Orbit,
      link: links.nexpro.geoFences,
      flag: 'sidebar_geo_fences_menu',
      premium: true,
    },
    {
      key: 'checkIn',
      title: 'Check-in',
      icon: CheckCircle,
      link: links.nexpro.checkIn,
      flag: 'sidebar_check_in_menu',
      premium: true,
    },
    // {
    //   key: 'travel',
    //   title: 'sidebar/nexproMenus:travel',
    //   icon: Truck,
    //   link: links.nexpro.travel,
    // },
    {
      key: 'controlRoom',
      title: 'Control Room',
      icon: SlidersHorizontal,
      link: links.nexpro.controlRoom,
      flag: 'sidebar_control_room_menu',
    },

    {
      key: 'reports',
      title: 'sidebar/nexproMenus:reports',
      icon: FileText,
      link: '#',
      flag: 'sidebar_reports',
      children: [
        {
          key: 'reportsFleets',
          title: 'sidebar/nexproMenus:fleets',
          link: links.nexpro.reports.fleets,
        },
        {
          key: 'reportsActivities',
          title: 'sidebar/nexproMenus:activities',
          link: links.nexpro.reports.activities,
        },

        {
          key: 'driverAwards',
          title: 'sidebar/nexproMenus:driverAwards',
          link: links.nexpro.reports.driverAwards,
          flag: 'sidebar_reports_driver_awards_menu',
        },
        {
          key: 'performance',
          title: 'sidebar/nexproMenus:performanceReport',
          link: links.nexpro.reports.performance,
          flag: 'sidebar_reports_performance_menu',
        },
        {
          key: 'reportsHeatmap',
          title: 'sidebar/nexproMenus:heatmap',
          link: links.nexpro.reports.heatmap,
          premium: true,
        },
      ],
    },
    // {
    //   key: 'newActivations',
    //   title: 'sidebar/nexproMenus:newActivations',
    //   icon: PlusSquare,
    //   link: links.nexpro.newActivations,
    // },
  ];
};
