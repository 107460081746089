import { lazy } from 'react';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import { DashboardRoutes } from '@/apps/nexpro/modules/dashboard/index.routes';
import ErrorBoundary from '@/common/components/ErrorBoundary';
import { SettingsRoutes } from '@/common/modules/settings/index.routes';
import { MapLayout } from '@/layouts/MapLayout';
import { Root } from '@/layouts/Root';

import { CheckInRoutes } from '../modules/checkIn/index.routes';
import { ClientsRoutes } from '../modules/clients/index.routes';
import { ControlRoomRoutes } from '../modules/controlRoom/index.routes';
import { GeoFencesRoutes } from '../modules/geofences/index.routes';
import {
  JourneyRoutes,
  MonitoringRoutes,
} from '../modules/monitoring/index.routes';
import { NewActivationsRoutes } from '../modules/newActivations/index.routes';
import { PerformanceRoutes } from '../modules/performance/index.routes';
import { RegistrationsRoutes } from '../modules/registrations/index.routes';
import { ReportsRoutes } from '../modules/reports/index.routes';
import { TravelRoutes } from '../modules/travel/index.routes';
import { UserProfileRoutes } from '../modules/userProfile';

const NotFoundComponent = lazy(() => import('@/common/components/404'));
const PrivacyPolicy = lazy(() => import('@/common/components/PrivacyPolicy'));

const NexproRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      ...SettingsRoutes,
      ...ClientsRoutes,
      ...DashboardRoutes,
      ...RegistrationsRoutes,
      ...MonitoringRoutes,
      ...PerformanceRoutes,
      ...CheckInRoutes,
      ...TravelRoutes,
      ...GeoFencesRoutes,
      ...ControlRoomRoutes,
      ...ReportsRoutes,
      ...UserProfileRoutes,
      ...NewActivationsRoutes,
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/',
    element: <MapLayout />,
    children: [...JourneyRoutes],
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '*',
    element: <NotFoundComponent />,
  },
];

export const NexproApp = createBrowserRouter(NexproRoutes);
