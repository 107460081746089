import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const NewActivations = lazy(() => import('./pages/newActivations'));

export const NewActivationsRoutes: RouteObject[] = [
  {
    path: links.nexpro.newActivations,
    element: <NewActivations />,
  },
];
