import { create } from 'zustand';

import { UserRoleEnum } from '@/common/types/Role';

interface IFiltersUsersTableStore {
  isActive: boolean;
  freeSearch: string;
  roles?: UserRoleEnum[];
  updateFreeSearch: (freeSearch: string) => void;
  updateRoles: (roles?: UserRoleEnum[]) => void;
  updateIsActive: (isActive: boolean) => void;
  clearFilters: () => void;
  setFilters: (
    freeSearch?: string,
    roles?: UserRoleEnum[],
    isActive?: boolean,
  ) => void;
}

export const useFiltersUsersTable = create<IFiltersUsersTableStore>(set => {
  return {
    freeSearch: '',
    isActive: true,
    roles: undefined,
    updateFreeSearch(freeSearch = '') {
      set({ freeSearch });
    },
    updateRoles(roles = undefined) {
      set({ roles });
    },
    updateIsActive(isActive = true) {
      set({ isActive });
    },
    clearFilters() {
      set({ freeSearch: '', roles: undefined, isActive: true });
    },
    setFilters(freeSearch = '', roles = undefined, isActive = true) {
      set({ freeSearch, roles, isActive });
    },
  };
});
